body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #93bf955e;
}

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.title {
  color: rgb(46, 125, 46);
  font-size: 50px;
  padding: 30px;
}

.explaination-card {
  color: rgb(46, 125, 46);
  size: 30px;
  padding: 16px;
  width: 50%;
  background-color: rgb(250, 250, 231);
  border-radius: 7px;
  line-height: 170%;
}
.bold {
  font-weight: bolder;
}
.weather-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgb(133, 218, 242);
  padding: 10px;
  display: flex;
  width: 45%;
  border-radius: 10px;
  color: white;
}

.weather-icon {
  font-size: 75px;
}

.weather-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.city {
  font-size: 24px;
  font-weight: bold;
}

.temp {
  font-size: 25px;
  font-weight: bold;
  margin-left: auto;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.search-box {
  width: 50%;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  gap: 11px;

  flex-direction: column;
  align-items: center;
}

.search-input {
  padding: 20px;
  border: none;
  border-radius: 10px 0 0 10px;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  width: 200px;
  border-radius: 0;
}

.date-input {
  width: 200px;
  padding: 20px;
  border: none;
  max-width: 100%;
}

.search-button {
  padding: 10px;
  background-color: #9ad09c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 100%;
  width: 180px;
  margin-top: 20px;
}

.search-button:hover {
  background-color: #45a049;
}

.card-container {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
